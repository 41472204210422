ion-split-pane {
    --side-max-width: 250px;
}

ion-header.no-borders::after {
    background: none;
}

ion-header.no-borders ion-toolbar {
    --border-width: 0 !important;
}

ion-content {
    --background: #f1f1f1;
}

ion-content.menu-content {
    --background: #fff;
}

ion-modal.ion-modal-scrollable .ion-page {
    overflow: auto;
}

ion-item.transparent {
    --background: .transparent;
}

ion-card {
    border-radius: 16px;
}

.ion-alert-edit .alert-wrapper {
    width: 600px;
    max-width: 600px;
}

.page-background {
    background-color: #f5f5f5;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.card-content-loading {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-content-loading-large {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-search {
    padding: 0 !important;
    margin: auto !important;
}

.input-search .searchbar-input {
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    background-color: var(--ion-color-light-tint);
}